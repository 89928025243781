<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo brand-logo-unlogin">
        <!-- <vuexy-logo /> -->
        <img
          src="@/assets/images/logo/transfersmile_big_logo.png"
          alt=""
        >
        <h2 class="brand-text text-primary ml-1 mt-2">
          Merchant Center
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Please reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >

              <!-- Phone number -->
              <telephone-number
                ref="telephoneNumber"
                :area-code.sync="areaCode"
                :phone-number.sync="phoneNumber"
                :verification-code.sync="verificationCode"
              />
              <!-- password -->
              <password-component
                ref="passwordComponent"
                :password.sync="password"
                :confirm-password.sync="confirmPassword"
              />
              <b-button
                type="submit"
                variant="primary"
                block
              >
                Confirm
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BInputGroup,
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { aseEncrypt, showToast } from '@/libs/utils'
import TelephoneNumber from './components/PhoneNumber.vue'
import PasswordComponent from './components/PasswordComponent.vue'

const _ = require('lodash')
const baseUrlBean = require('@/libs/baseUrl')

export default {
  components: {
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BInputGroup,
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    TelephoneNumber,
    PasswordComponent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      areaCode: '+55',
      phoneNumber: '',
      verificationCode: '',
      password: '',
      confirmPassword: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  provide() {
    return {
      scene: '5',
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.$showLoading()
          const params = {
            platform: 2,
            new_password: aseEncrypt(_.trim(this.password)),
            confirm_password: aseEncrypt(_.trim(this.confirmPassword)),
            email: _.trim(this.phoneNumber),
            verification_code: _.trim(this.verificationCode),
          }
          const res = await this.$api.forgetPassword(params).catch(error => {
            this.$hideLoading()
            showToast({ proxy: this, title: 'danger', message: error.message })
          })
          this.$hideLoading()
          const { code, message } = res.data
          if (code === 200) {
            const that = this
            showToast({ proxy: this, title: 'success', message })
            setTimeout(() => {
              that.$router.push({ name: 'auth-login' })
            }, 2000)
          } else {
            showToast({ proxy: this, title: 'danger', message })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.brand-logo-unlogin img {
  width: auto;
  height: 70px;
}
.auth-wrapper {
  .input-group {
    flex-wrap: nowrap !important;
  }
  .input-group-prepend {
    height: 38px;
  }
  .b-dropdown-country {
    img {
      width: 20px;
      height: auto;
    }
    & > button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .validate-span-box {
    display: flex;
    width: 100%;
    flex-wrap: nowrap !important;
  }
  input[name="login-email"] {
    border-radius: 0;
  }
  .b-append-btn {
    & > button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
